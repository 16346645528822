<template>
  <div class="app-container">
    <div class="header-warpper">
      <div>晋级及会员权益设置</div>
      <color-text-btn class="history-button" @click="showDiscountHistory">查看折扣历史记录 ></color-text-btn>
    </div>
    <div class="table-warpper">
      <el-table
        border
        v-loading="tableLoading"
        class="table-class"
        :data="tableData"
        :header-cell-style="{ borderRight: '1px solid #ebeef5' }"
      >
        <el-table-column prop="level" label="会员等级"> </el-table-column>
        <el-table-column label="采购额范围">
          <template slot-scope="{ row, $index }">
            <div class="flex">
              <div class="input-disabled">
                <el-input style="width: 120px" size="small" readonly v-model="row.minSalesAmount"></el-input>
              </div>
              <div class="rangeLine"></div>
              <el-input
                :class="{ 'table-disabled': row.rangeError }"
                @blur="maxBlured(row.maxSalesAmount, $index)"
                style="width: 120px"
                size="small"
                v-model="row.maxSalesAmount"
              >
              </el-input>
            </div>
            <div v-if="row.rangeError" class="valid-warpper">
              <span class="warning-message">{{ row.rangeError }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="商品价格折扣">
          <template slot-scope="{ row, $index }">
            <div v-if="row.isNormal === 1">{{ row.priceDiscount }}%</div>
            <div class="flex" v-else>
              <div>普通用户价 *</div>
              <el-input
                size="small"
                style="width: 120px; margin-left: 3px"
                v-model="row.priceDiscount"
                :class="{ 'table-disabled': row.discountError }"
                @blur="priceBlured(row.priceDiscount, $index)"
              ></el-input>
              <div>%</div>
            </div>
            <div v-if="row.discountError" class="valid-warpper">
              <span class="message">{{ row.discountError }}</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="button-group">
        <el-button type="default" @click="onCancel">取消</el-button>
        <loading-btn type="primary" @click="handleSave">保存</loading-btn>
      </div>
    </div>
    <historyDialog v-if="showDialog" :visible.sync="showDialog" />
  </div>
</template>

<script>
import historyDialog from './module/discountHistoryDialog'
import { deepClone } from '@/components/avue/utils/util'
import { getVipList, setVipDiscount } from '@/api/vipLevel'

export default {
  components: { historyDialog },
  data() {
    return {
      showDialog: false,
      //所有用户等级列表(包括禁用和非禁用的)，实际上禁用的不予显示，需要使用computed属性进行过滤操作
      tableData: [],
      tableDataCopy: [],
      tableLoading: true
    }
  },
  methods: {
    onCancel() {
      this.$router.push('/system/vip')
    },
    async handleSave() {
      const memberLevelDTOList = this.tableData.map(
        ({ id, levelName, maxSalesAmount, minSalesAmount, priceDiscount }) => ({
          id,
          maxSalesAmount: Number(maxSalesAmount),
          minSalesAmount: Number(minSalesAmount),
          priceDiscount: priceDiscount / 100,
          levelName
        })
      )
      this.tableData.map((item, index) => {
        if (Number(item.minSalesAmount) >= Number(item.maxSalesAmount)) {
          this.$set(item, 'rangeError', '采购额终止范围必须大于采购额初始范围')
          return
        }
      })
      const valid = this.tableData.find((item, index) => {
        if (!item.rangeError && !item.discountError) return
        return item.rangeError || item.discountError
      })
      console.log(valid, this.tableData)
      if (valid && (valid.rangeError || valid.discountError)) return
      //调用保存接口
      try {
        console.log(111)
        const { code } = await setVipDiscount({ memberLevelDTOList })
        if (code == 0) {
          this.$router.push('/system/vip')
          this.$message.success('保存成功')
        }
      } catch (error) {}
    },
    showDiscountHistory() {
      this.showDialog = true
    },
    priceBlured(val, index) {
      let msg = ''
      if (!Number.isInteger(Number(val)) || val <= 0 || val >= 100) {
        msg = '请输入一个介于1到99之间的整数'
      }
      this.$set(this.tableData[index], 'discountError', msg)
    },
    maxBlured(val, index) {
      let msg = ''
      if (!Number.isInteger(Number(val)) || val < 0) {
        msg = '请输入一个大于0的整数'
      }
      if (this.tableData[index].maxSalesAmount <= this.tableData[index].minSalesAmount) {
        msg = '采购额终止范围必须大于采购额初始范围'
      }
      this.$set(this.tableData[index], 'rangeError', msg)
      if (index + 1 < this.tableData.length) {
        this.tableData[index + 1].minSalesAmount = Number(val) + 1
      }
    }
  },
  async created() {
    const page = { pageIndex: 1, pageSize: 0 }
    try {
      this.tableLoading = true
      const { code, detail } = await getVipList({ page })
      if (code == 0) {
        this.tableData = detail.map((item) => {
          const priceDiscount =
            (item.priceDiscount * 100) % 1 > 0.5
              ? Math.ceil(item.priceDiscount * 100)
              : Math.floor(item.priceDiscount * 100)
          item.priceDiscount = priceDiscount
          return item
        })
      }
    } finally {
      this.tableLoading = false
    }
    this.tableDataCopy = deepClone(this.tableData)
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 30px 0 0 40px;
  .header-warpper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60%;
    .history-button {
      font-size: 16px;
    }
  }
  .table-warpper {
    font-size: 14px;
    margin-top: 20px;
    .table-class {
      width: 60%;
      border-left: 1px solid #ebeef5;
      border-top: 1px solid #ebeef5;
    }
    .table-disabled {
      border: 1px solid red;
    }
    ::v-deep {
      .el-table th,
      .el-table td {
        padding-bottom: 25px;
      }
    }
    .valid-warpper {
      .message,
      .warning-message {
        font-size: 12px;
        color: red;
        position: absolute;
        right: 20px;
      }
      .message {
        right: 0px;
        left: 95px;
      }
    }
    .flex {
      display: flex;
      align-items: center;
      .input-disabled {
        ::v-deep {
          .el-input__inner {
            background: #f0f0f0;
          }
        }
      }
    }
    .button-group {
      margin-top: 40px;
    }
    .rangeLine {
      height: 1px;
      width: 10px;
      border: 1px solid #dcdee0;
      margin: 0 13px 0 13px;
    }
  }
}
</style>
